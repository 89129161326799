/*  General Styles  */
html {
  scroll-behavior: smooth;
}
@font-face {
  font-family: 'GraphikLight';
  src: url('/public/fonts/GraphikLight.otf');
}
@font-face {
  font-family: 'GraphikRegular';
  src: url('/public/fonts/GraphikRegular.otf');
}
@font-face {
  font-family: 'GraphikMedium';
  src: url('/public/fonts/GraphikMedium.otf');
}
@font-face {
  font-family: 'GraphikSemibold';
  src: url('/public/fonts/GraphikSemibold.otf');
}
@font-face {
  font-family: 'GraphikBold';
  src: url('/public/fonts/GraphikBold.otf');
}

body,
html {
  width: 100%;
  height: 100%;
  background: ##111218;
}
.bg-clr-body {
  background: ##111218;
}
body,
p {
  color: #fff;
  font-family: 'GraphikRegular';
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}
.p-small,
.p-large {
  font-family: 'GraphikRegular';
}
.p-heading {
  margin-bottom: 40;
  text-align: center;
}

h1 {
  color: #fff;
  font-family: 'GraphikMedium';
}
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
  font-family: 'GraphikMedium';
}
.li-space-lg li {
  margin-bottom: 30px;
}

a {
  color: #fff;
  text-decoration: underline;
}
a:hover {
  color: #fff;
  text-decoration: underline;
}

.btn-solid-reg {
  display: inline-block;
  padding: 1.1875rem 2.125rem 1.1875rem 2.125rem;
  border: 0.125rem solid #32b5ff;
  border-radius: 2rem;
  background-color: #32b5ff;
  color: #fff;
  font-family: 'GraphikMedium';
  text-decoration: none;
  transition: all 0.2s;
}

.btn-solid-reg:hover {
  border: 0.125rem solid #32b5ff;
  background-color: transparent;
  color: #32b5ff;
  text-decoration: none;
}

.btn-solid-lg {
  font-family: 'GraphikMedium';
  display: inline-block;
  padding: 18px 39px;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.2px;
  border: 0.125rem solid #32b5ff;
  background-color: #32b5ff;
  color: #fff !important;
  text-decoration: none;
  transition: all 0.2s;
  box-shadow: 0px 4px 31px rgb(0 0 0 / 15%);
  text-align: center;
}

.btn-solid-lg:hover {
  border: 0.125rem solid #32b5ff;
  background-color: transparent;
  color: #32b5ff;
  text-decoration: none;
}

.btn-solid-lg .fab {
  margin-right: 0.5rem;
  font-size: 1.25rem;
  line-height: 0;
  vertical-align: top;
}

.btn-solid-lg .fab.fa-google-play {
  font-size: 1rem;
}

.btn-outline-reg {
  display: inline-block;
  padding: 1.1875rem 2.125rem 1.1875rem 2.125rem;
  border: 0.125rem solid #333;
  border-radius: 2rem;
  background-color: transparent;
  color: #333;
  font: 700 0.875rem/0 'Open Sans', sans-serif;
  text-decoration: none;
  transition: all 0.2s;
}

.btn-outline-reg:hover {
  border: 0.125rem solid #333;
  background-color: #333;
  color: #fff;
  text-decoration: none;
}

.btn-outline-lg {
  display: inline-block;
  padding: 1.5rem 2.375rem 1.5rem 2.375rem;
  border: 0.125rem solid #333;
  border-radius: 2rem;
  background-color: transparent;
  color: #333;
  font: 700 0.875rem/0 'Open Sans', sans-serif;
  text-decoration: none;
  transition: all 0.2s;
}

.btn-outline-lg:hover {
  border: 0.125rem solid #333;
  background-color: #333;
  color: #fff;
  text-decoration: none;
}

.btn-outline-sm {
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s;
  background: transparent;
  font-family: 'GraphikMedium';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.2px;
  padding: 18px 39px;
  color: #fff !important;
  text-align: center;
  border: 0.125rem solid #fff;
  box-shadow: 0px 4px 31px rgb(0 0 0 / 15%);
}

.btn-outline-sm:hover {
  border: 0.125rem solid #32b5ff;
  background-color: #32b5ff;
  color: #fff;
  text-decoration: none;
}

.navbar-custom .collapse.navbar-collapse span.nav-item a.btn-outline-sm,
.navbar-custom .collapse.navbar-collapse span.nav-item a.btn-solid-lg {
  width: 139px;
  padding: 12px 5px;
}
.inner-page-header {
  position: relative;
  overflow: hidden;
  padding-top: 12rem;
  padding-bottom: 0rem;
  background-color: ##111218;
}
.padding-top-bottom-90px {
  padding: 90px;
}
.padding-top-60px {
  padding-top: 60px;
}
.padding-bottom-60px {
  padding-bottom: 60px;
}
.space-10px {
  display: block;
  height: 10px;
}
.space-20px {
  display: block;
  height: 20px;
}
.space-30px {
  display: block;
  height: 30px;
}
.space-40px {
  display: block;
  height: 40px;
}
.space-50px {
  display: block;
  height: 50px;
}
.space-60px {
  display: block;
  height: 60px;
}
.space-70px {
  display: block;
  height: 70px;
}
.space-80px {
  display: block;
  height: 80px;
}
.space-90px {
  display: block;
  height: 90px;
}

/* Second section css */
.align-items-center {
  align-items: center;
}
.top-content-section h2 {
  font-size: 52px;
  line-height: 62px;
  letter-spacing: 0.2px;
  color: #ffffff;
  font-family: 'GraphikMedium';
  margin-bottom: 27px;
}
.top-content-section p.p-heading {
  font-family: 'GraphikRegular';
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
  max-width: 737px;
}
#second-section ul.list-unstyled li.media .media-body {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: #ffffff;
  font-family: 'GraphikMedium';
}
#second-section ul.list-unstyled li.media img.img-fluid {
  padding-right: 15px;
}
#second-section ul.list-unstyled li.media {
  margin-bottom: 50px;
  align-items: center;
}
#second-section p.p-heading {
  margin-bottom: 1rem;
}
#second-section ul.list-unstyled li.media .media-body {
  text-align: left;
}
/* End Second section css */

/* Thrid section css */
#thrid-section .top-content-section p.p-small {
  max-width: 704px;
  margin: 0px auto;
}
/* End Thrid section css */

/* Pricing Forth Section css */
.clr-green {
  color: #32b5ff !important;
}
/* .pricing-section-info .top-content-section h2 {font-size: 40px;line-height: 55px;margin-bottom: 10px;}
.pricing-section-info .top-content-section p.p-small {font-size: 20px;line-height: 27px;color: #848199;}


.pricing-table-info .nav-tabs {
    border: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 22px;
    max-width: 267px;
    margin: 0px auto;
    margin-bottom: 70px;
}
.pricing-table-info .nav-tabs li.nav-item {
    width: 50%;
    margin: 0px;
    border: none;
}
.pricing-table-info .nav-tabs .nav-link {
    font-family: "GraphikMedium";
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.833333px;
    color: #848199;
    text-decoration: none;
	border: none;
	padding: 16px 0px;
    border-radius: 22px;
}
.pricing-table-info .nav-tabs .nav-link.active, .pricing-table-info .nav-tabs .nav-item.show .nav-link,
.pricing-table-info .nav-tabs .nav-item .nav-link:hover {
    background: #32B5FF;
    box-shadow: 0px 5px 7px rgb(82 67 194 / 23%);
    border-radius: 22px;
    border: none;
    color: #fff;
}
.save-up-txt-info {
    position: absolute;
    margin-top: -40px;
    margin-left: 270px;
}
.save-price-txt {
	background: linear-gradient(90deg, #33D1C0 0.08%, #76F030 76.12%);
    width: 66px;
    height: 66px;
    display: grid;
    border-radius: 50px;
    align-items: center;
}
.save-price-txt h3 {
    font-size: 13px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.833333px;
    color: #FFFFFF;
    margin: 0px;
}
.save-price-txt h3 .save-price-amount {
    font-size: 20px;
    line-height: 18px;
    font-family: "GraphikBold";
	padding-top: 5px;
    display: block;
}

.pricing-table-content {
    position: relative;
} */
/* .pricing-table-box {
    background: #FFFFFF;
    border-radius: 26px;
    padding: 40px;
} */
/* .pricing-table-content .table-price-amount h3.table-price {
    font-size: 36px;
    line-height: 46px;
    color: #231D4F;
    font-family: "GraphikSemibold";
	margin-bottom: 20px;
}
.pricing-table-content .table-price-amount h3.table-price span.txt-month {
    font-size: 17px;
    line-height: 23px;
    color: #848199;
    font-family: "GraphikMedium";
} */
/* .pricing-desc-content h3 {
    font-size: 28px;
    line-height: 38px;
    color: #231D4F;
    font-family: "GraphikSemibold";
	margin-bottom: 24px;
} */
/* ul.ul-tag-pricing-table {
    font-size: 12px;
    line-height: 18px;
    color: #848199;
    font-family: "GraphikRegular";
    padding-left: 20px;
    list-style: none;
	position: relative;
} */
/* .pricing-desc-content {
    margin-bottom: 40px;
} */
/* ul.ul-tag-pricing-table li {
    margin-bottom: 11px;
	padding-left: 10px;
}
ul.ul-tag-pricing-table li::before {
    content: url(/public/assets/check-circle-1.png);
    display: block;
    position: absolute;
    left: 0px;
} */
/* .table-pricing-btn a.btn-pricing-start {
    text-decoration: none;
    background: #e3e2ed;
    border-radius: 24px;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #391463;
    width: 100%;
    display: block;
    padding: 12.5px 10px;
    font-family: "GraphikMedium";
    max-width: 220px;
    margin: 0px auto;
} */
/* .most-popular-txt-pricing-table {
    background: #34D1BE;
    border-radius: 13.5px;
    padding: 6px 6px;
    max-width: 121px;
    text-align: right;
    width: 100%;
    display: block;
    margin: 0px auto;
    margin-right: 0;
    margin-bottom: 5px;
}
.most-popular-txt-pricing-table p {
    margin: 0px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.833333px;
    color: #FFFFFF;
}
.pricing-green-box {
	background: #32B5FF;
    box-shadow: 0px 42px 34px rgb(82 67 194 / 30%);
    border-radius: 26px;
    margin-top: -70px;
    padding: 15px 10px 24px 10px;
}
.pricing-green-box h3.table-price,
.pricing-green-box span.txt-month,
.pricing-green-box .pricing-desc-content h3{
    color: #fff!important;
} */
/* .pricing-green-box ul.ul-tag-pricing-table{
    color: #fff!important;
} */
/* .pricing-green-box a.btn-pricing-start {
    background: #112D57;
    color: #fff;
} */
/* .pricing-green-box ul.ul-tag-pricing-table li::before {
    content: url(/public/assets/check-circle-1-white.png);
} */

/* End Pricing Forth Section css */

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url('/public/assets/down-arrow.png');
  background-position: 96% 50%;
  background-repeat: no-repeat;
  outline: none;
}

select::-ms-expand {
  display: none;
}

/* Form Success And Error Message Animation - Animate.css */
@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
/* end of form success and error message animation - Animate.css */

/* Fade-move Animation For Details Lightbox - Magnific Popup */
/* at start */
.my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  transition: all 0.2s ease-out;
  -webkit-transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
  -ms-transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
  transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: translateY(0) perspective(37.5rem) rotateX(0);
  -ms-transform: translateY(0) perspective(37.5rem) rotateX(0);
  transform: translateY(0) perspective(37.5rem) rotateX(0);
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
  opacity: 0;
  -webkit-transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg);
  -ms-transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg);
  transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg);
}

/* dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
  opacity: 0.8;
}
/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
  opacity: 0;
}
/* end of fade-move animation for details lightbox - magnific popup */

/* Fade Animation For Image Lightbox - Magnific Popup */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation: fadeIn 0.6s;
  animation: fadeIn 0.6s;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation: fadeOut 0.8s;
  animation: fadeOut 0.8s;
}
/* end of fade animation for details lightbox - magnific popup */

/*************************/
/*     02. Preloader     */
/*************************/
.spinner-wrapper {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #f7fafd;
}

.spinner {
  position: absolute;
  top: 50%; /* centers the loading animation vertically one the screen */
  left: 50%; /* centers the loading animation horizontally one the screen */
  width: 3.75rem;
  height: 1.25rem;
  margin: -0.625rem 0 0 -1.875rem; /* is width and height divided by two */
  text-align: center;
}

.spinner > div {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: #32b5ff;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

/**************************/
/*     03. Navigation     */
/**************************/
.navbar-custom {
  font-family: 'GraphikMedium';
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  letter-spacing: 0.2px;
  transition: all 0.2s;
  background: transparent
}
.navbar-custom .navbar-nav {
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}

.navbar-custom .nav-item .nav-link {
  padding: 0.625rem 0.75rem;
  color: #fff;
  text-decoration: none;
  transition: all 0.2s ease;
}

.navbar-custom .nav-item .nav-link:hover,
.navbar-custom .nav-item .nav-link.active {
  color: #32b5ff;
}

/* Dropdown Menu */
.navbar-custom .dropdown:hover > .dropdown-menu {
  display: block; /* this makes the dropdown menu stay open while hovering it */
  min-width: auto;
  animation: fadeDropdown 0.2s; /* required for the fade animation */
}

@keyframes fadeDropdown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navbar-custom .dropdown-toggle {
  cursor: default;
}

.navbar-custom .dropdown-toggle:focus {
  outline: 0;
}

.navbar-custom .dropdown-menu {
  margin-top: 0;
  border: none;
  border-radius: 0.25rem;
  background-color: #f7fafd;
}
.navbar-custom li.nav-item.dropdown .nav-link {
  color: #a1a1a1;
}
.navbar-custom .dropdown-item {
  color: #333;
  text-decoration: none;
}

.navbar-custom .dropdown-item:hover {
  background-color: #f7fafd;
}

.navbar-custom .dropdown-item .item-text {
  font-family: 'GraphikRegular';
}

.navbar-custom .dropdown-item:hover .item-text {
  color: #32b5ff;
}

.navbar-custom .dropdown-divider {
  width: 100%;
  height: 1px;
  margin: 0.75rem auto 0.725rem auto;
  border: none;
  background-color: #c4d8dc;
  opacity: 0.2;
}
/* end of dropdown menu */

.navbar-custom .nav-item .btn-outline-sm:hover {
  background-color: #32b5ff;
  color: #fff;
}

.navbar-custom .navbar-toggler {
  padding: 0;
  border: none;
  color: #333;
  font-size: 2rem;
}

.navbar-custom
  button[aria-expanded='false']
  .navbar-toggler-awesome.fas.fa-times {
  display: none;
}

.navbar-custom
  button[aria-expanded='false']
  .navbar-toggler-awesome.fas.fa-bars {
  display: inline-block;
}

.navbar-custom
  button[aria-expanded='true']
  .navbar-toggler-awesome.fas.fa-bars {
  display: none;
}

.navbar-custom
  button[aria-expanded='true']
  .navbar-toggler-awesome.fas.fa-times {
  display: inline-block;
  margin-right: 0.125rem;
}

/*********************/
/*    04. Header     */
/*********************/
.header {
  position: relative;
  overflow: hidden;
  padding-top: 8rem;
  padding-bottom: 0rem;
  background-color: #111218;
}

.header .text-container {
  margin-bottom: 3rem;
}

.header h1 {
  font-family: 'GraphikMedium';
  font-style: normal;
  font-weight: 400;
  font-size: 65px;
  line-height: 88px;
  text-align: center;
  letter-spacing: 0.2px;
  max-width: 684px;
  margin: 0px auto;
  margin-bottom: 37px;
}
.header .p-heading {
  font-family: 'GraphikRegular';
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ffffff;
  margin-bottom: 90px;
}

.header .btn-solid-lg {
  margin-right: 30px;
  margin-bottom: 1.125rem;
  margin-left: 0.5rem;
}

.header .deco-white-circle-1,
.header .deco-white-circle-2,
.header .deco-blue-circle,
.header .deco-yellow-circle,
.header .deco-green-diamond {
  display: none;
}

/**********************************************/
/*     07. Description 1 Details Lightbox     */
/**********************************************/
.lightbox-basic {
  position: relative;
  max-width: 71.875rem;
  margin: 2.5rem auto;
  padding: 3rem 1rem;
  background-color: #fff;
  text-align: left;
}

.lightbox-basic .image-container {
  margin-bottom: 3rem;
  text-align: center;
}

.lightbox-basic h3 {
  margin-bottom: 0.5rem;
}

.lightbox-basic hr {
  width: 2.75rem;
  margin-top: 0.125rem;
  margin-bottom: 1rem;
  margin-left: 0;
  border-top: 0.125rem solid #e0e0e0;
}

.lightbox-basic h4 {
  margin-top: 1.75rem;
  margin-bottom: 0.625rem;
}

.lightbox-basic .list-unstyled {
  margin-bottom: 1.5rem;
}

.lightbox-basic .list-unstyled .far {
  color: #19ca94;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.lightbox-basic .list-unstyled .media-body {
  margin-left: 0.625rem;
}

.lightbox-basic .list-unstyled {
  margin-bottom: 1.5rem;
}

/* Action Button */
.lightbox-basic .btn-solid-reg.mfp-close {
  position: relative;
  width: auto;
  height: auto;
  color: #fff;
  opacity: 1;
}

.lightbox-basic .btn-solid-reg.mfp-close:hover {
  color: #32b5ff;
}
/* end of action Button */

/* Back Button */
.lightbox-basic .btn-outline-reg.mfp-close.as-button {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  margin-left: 0.375rem;
  padding: 1.25rem 2.25rem 1.25rem 2.25rem;
  border: 0.125rem solid #333;
  color: #333;
  opacity: 1;
}

.lightbox-basic .btn-outline-reg.mfp-close.as-button:hover {
  color: #fff;
}
/* end of back button */

/* Close X Button */
.lightbox-basic button.mfp-close.x-button {
  position: absolute;
  top: -0.125rem;
  right: -0.125rem;
  width: 2.75rem;
  height: 2.75rem;
  color: #555;
}
/* end of close x button */

/****************************/
/*     08. Description 2    */
/****************************/
.tabs {
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-color: #e8eff6;
}

.tabs .tabs-container {
  margin-bottom: 5rem;
}

.tabs .nav-tabs {
  margin-bottom: 1.125rem;
  border: none;
}

.tabs .nav-item {
  width: 100%;
  margin-bottom: 0.75rem;
  text-align: center;
}

.tabs .nav-link {
  padding: 0.5rem 1.5rem;
  border-radius: 0;
  background-color: #f7fafd;
  color: #777;
  font-weight: 700;
  font-size: 1.125rem;
  text-decoration: none;
  transition: all 0.2s ease;
}

.tabs .nav-link.active {
  background-color: #9a67e7;
  color: #fff;
}

.tabs .nav-link:hover {
  background-color: #9a67e7;
  color: #fff;
}

.tabs .nav-link .fas,
.tabs .nav-link .far {
  margin-right: 0.375rem;
}

.tabs .li-space-lg li {
  margin-bottom: 0.875rem;
}

.tabs .list-unstyled .fas,
.tabs .list-unstyled .far {
  color: #19ca94;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.tabs .list-unstyled .media-body {
  margin-left: 0.625rem;
}

.tabs #tab-1 .list-unstyled {
  margin-bottom: 1.5rem;
}

.tabs #tab-1 .btn-solid-reg {
  margin-right: 0.5rem;
}

/***************************/
/*     10. Screenshots     */
/***************************/
.slider {
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-color: #e8eff6;
}

.slider .slider-container {
  position: relative;
}

.slider .swiper-container {
  position: static;
  width: 90%;
  text-align: center;
}

.slider .swiper-button-prev,
.slider .swiper-button-next {
  top: 50%;
  width: 1.125rem;
}

.slider .swiper-button-prev:focus,
.slider .swiper-button-next:focus {
  /* even if you can't see it chrome you can see it on mobile device */
  outline: none;
}

.slider .swiper-button-prev {
  left: -0.5rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23777777'%2F%3E%3C%2Fsvg%3E");
  background-size: 1.125rem 1.75rem;
}

.slider .swiper-button-next {
  right: -0.5rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23777777'%2F%3E%3C%2Fsvg%3E");
  background-size: 1.125rem 1.75rem;
}

/*  Testimonials  */
.testimonial-body .testimonial-content {
  background: ##111218;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 40px;
}
.testimonial-list {
  display: grid;
  grid-template-columns: 50px 1fr;
  column-gap: 20px;
  margin-bottom: 30px;
}
.testimonial-auther-txt h3 {
  font-family: 'GraphikSemibold';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #ffffff;
  margin-bottom: 0px;
}
.testimonial-auther-txt h4 {
  font-size: 16px;
  font-family: 'GraphikMedium';
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #ffffff;
  margin-bottom: 0px;
}
.testimonial-text-info p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #ffffff;
  margin-bottom: 0px;
}
.about-us-top-txt .p-heading {
  max-width: 1150px !important;
  width: 100%;
  margin-bottom: 60px;
}
.icon-left-box {
  display: grid;
  grid-template-columns: 40px 1fr;
  column-gap: 10px;
  margin-bottom: 15px;
}
.icon-left-box .right-txt h4 {
  font-family: 'GraphikMedium';
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: #ffffff;
}
.icon-box-below-txt p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  font-family: 'GraphikRegular';
}
.icon-box-below-txt ul.ul-tag-icon {
  font-size: 16px;
  line-height: 24px;
  font-family: 'GraphikRegular';
  letter-spacing: 0.2px;
}

/* contact us section css  */
.padding-bottom-90px {
  padding-bottom: 90px;
}

.contact-us-form-info {
  background: #ffffff;
  box-shadow: 0px 20px 27px rgb(0 0 0 / 5%);
  border-radius: 16px;
  position: relative;
}
.contact-form-css h3 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  color: #252f40;
  letter-spacing: -0.02222em;
}
.contact-form-css p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #67748e;
}
.contact-form label.control-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 1px;
  color: #252f40;
  margin-bottom: 15px;
}
.contact-form-css {
  padding: 60px 40px 40px 40px;
}
.contact-form .form-control {
  font-family: 'Open Sans';
  font-style: normal;
  padding: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 7px;
  color: #495057;
  background: #ffffff;
  border: 1px solid #d2d6da;
  box-sizing: border-box;
  border-radius: 8px;
}
.contact-form input.form-control {
  height: 40px;
}
.contact-form textarea.form-control {
  height: 150px;
}
.contact-form button.btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.04em;
  color: #ffffff;
  background: linear-gradient(90deg, #802ad8 0%, #32b5ff 74.56%);
  box-shadow: 0px 4px 7px -1px rgb(0 0 0 / 11%),
    0px 2px 4px -1px rgb(0 0 0 / 7%);
  border-radius: 20px;
  width: 100%;
  max-width: 133px;
  padding: 13px 0px;
  margin: 0px auto;
  text-align: center;
  display: block;
  margin-right: 0px;
}
.margin-bottom-0px {
  margin-bottom: 0px;
}
.contact-form .form-control:focus {
  outline: 0;
  box-shadow: unset;
}
.contact-form .form-control:focus-visible {
  outline: unset;
}
.contact-us-content-css h3 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-size: 30px;
  letter-spacing: -0.02666em;
  color: #fff;
  margin-bottom: 10px;
}
.contact-us-content-css p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
}
.contact-us-content-css ul.list-unstyled li a,
.contact-us-content-css ul.list-unstyled li .media-body {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}
.contact-us-content-css ul.list-unstyled {
  margin-top: 34px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 7px;
}
.contact-us-content-css ul.list-unstyled li i.fas {
  padding-right: 15px;
}
.contact-us-content-css ul.list-unstyled li {
  align-items: center;
}
.contact-us-content-css .footer-social-media-link a {
  color: #fff;
}
.contactus-bg-img {
  background-image: url(/public/assets/contact-background.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: left center;
}
.contact-us-content-css {
  padding: 30% 2% 10% 20%;
}

/*  Footer  */
.center {
  text-align: center;
}
#footer-above-section {
  padding: 70px 15px;
  background-image: url(/public/assets/cover.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#footer-above-section .text-container h3 {
  font-size: 42px;
  line-height: 57px;
  color: #ffffff;
}
#footer-above-section .text-container p.p-heading {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 40px;
}
#footer-above-section .text-container a.btn-solid-lg {
  padding: 11px 19px;
}
ul.footer-menu-custom li a {
  color: #fff;
}
ul.footer-menu-custom li {
  display: inline-block;
  margin-right: 70px;
}
ul.footer-menu-custom {
  font-family: 'GraphikMedium';
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: inline-block;
  column-gap: 70px;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  letter-spacing: 0.1px;
  margin-bottom: 35px;
}
.main-footer {
  padding-top: 40px;
  padding-bottom: 30px;
  background-color: ##111218;
}
.main-footer ul.list-unstyled li.media i.fas {
  color: #fff;
}
.main-footer p,
.main-footer h5,
.main-footer ul,
.main-footer a {
  color: rgba(255, 255, 255, 0.8);
}
.main-footer a {
  text-decoration: none;
}
.main-footer .footer-col {
  display: inline-block;
  width: 14.5rem;
  margin-right: 1rem;
  margin-bottom: 1.5rem;
  vertical-align: top;
}
.main-footer h5 {
  margin-bottom: 0.625rem;
}
.main-footer ul.list-unstyled li.media {
  margin-bottom: 23px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 7px;
  color: rgba(255, 255, 255, 0.8);
  align-items: baseline;
  font-family: 'Open Sans', sans-ser;
}
.main-footer .list-unstyled .fab,
.main-footer .list-unstyled .fas {
  width: 0.875rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
}
.main-footer .list-unstyled .media-body {
  margin-left: 16px;
}
.main-footer .fa-stack {
  width: 2em;
  margin-bottom: 0.75rem;
  margin-right: 0.375rem;
  font-size: 1.25rem;
}
.main-footer .fa-stack .fa-stack-1x {
  color: #fff;
  transition: all 0.2s ease;
}
.main-footer .fa-stack .fa-stack-2x {
  color: #999;
  transition: all 0.2s ease;
}
.main-footer .fa-stack:hover .fa-stack-1x {
  color: #fff;
}
.main-footer .fa-stack:hover .fa-stack-2x {
  color: #555;
}

/*************************/
/*     15. Copyright     */
/*************************/
.copyright {
  padding-top: 30px;
}
.copyright p,
.copyright a {
  font-size: 14px;
  line-height: 7px;
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.copyright a {
  text-decoration: none;
}

/**********************************/
/*     16. Back To Top Button     */
/**********************************/
a.back-to-top {
  position: fixed;
  z-index: 999;
  right: 0.75rem;
  bottom: 0.75rem;
  display: none;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 1.875rem;
  background: #333 url('/public/assets/up-arrow.png') no-repeat center 47%;
  background-size: 1.125rem 1.125rem;
  text-indent: -9999px;
}

a:hover.back-to-top {
  background-color: #555;
}

/***************************/
/*     17. Extra Pages     */
/***************************/
.ex-header {
  padding-top: 8rem;
  padding-bottom: 5rem;
  background-color: #f7fafd;
  text-align: center;
}

/*****************************/
/*     18. Media Queries     */
/*****************************/
/* Min-width 768px */
@media (min-width: 768px) {
  /* Header */
  .header .text-container {
    margin-bottom: 3rem;
  }

  .header .btn-solid-lg {
    margin-bottom: 0;
    margin-left: 0;
  }
  /* end of header */

  /* Small Features */
  .cards-1 .card {
    display: inline-block;
    margin-right: 1rem;
    margin-left: 1rem;
    width: 11rem;
    max-width: 100%;
    vertical-align: top;
  }
  /* end of small features */

  /* Description 2 */
  .tabs .nav-item {
    width: auto;
    margin-right: 0.625rem;
  }
  /* end of description 2 */

  /* Screenshots */
  .slider .swiper-button-prev {
    left: -0.625rem;
  }

  .slider .swiper-button-next {
    right: -0.625rem;
  }
  /* end of screenshots */

  /* Download */
  .basic-3 .btn-solid-lg {
    margin-bottom: 0;
    margin-left: 0;
  }
  /* end of download */

  /* Extra Pages */
  .ex-header {
    padding-top: 11rem;
    padding-bottom: 9rem;
  }
  /* end of extra pages */
}
/* end of min-width 768px */

/* Min-width 992px */
@media (min-width: 992px) {
  /* General Styles */
  .p-heading {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  h1 {
    font-size: 3rem;
    line-height: 3.875rem;
  }

  h2 {
    font-size: 2.5rem;
    line-height: 3.25rem;
  }
  /* end of general styles */

  /* Navigation */
  .navbar-custom {
    padding: 20px;
    box-shadow: none;
  }

  .navbar-custom .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
  }

  .navbar-custom .nav-item .nav-link {
    padding: 0.25rem 0.75rem;
  }

  .navbar-custom.top-nav-collapse {
    padding: 0.5rem 1.5rem 0.5rem 2rem;
    box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
  }

  .navbar-custom.top-nav-collapse .nav-item .nav-link {
    color: #fff;
  }

  .navbar-custom.top-nav-collapse .nav-item .nav-link:hover,
  .navbar-custom.top-nav-collapse .nav-item .nav-link.active {
    color: #32b5ff;
  }

  .navbar-custom .dropdown-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 0.75rem solid rgba(0, 0, 0, 0);
    border-radius: 0.25rem;
    box-shadow: 0 0.375rem 0.375rem 0 rgba(0, 0, 0, 0.1);
  }

  .navbar-custom.top-nav-collapse .dropdown-menu {
    border-top: 0.75rem solid rgba(0, 0, 0, 0);
    box-shadow: 0 0.375rem 0.375rem 0 rgba(0, 0, 0, 0.1);
  }

  .navbar-custom .dropdown-item {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .navbar-custom .dropdown-divider {
    width: 84%;
  }
  .navbar-custom div#navbarsExampleDefault {
    column-gap: 35px;
  }
  .navbar-custom div#navbarsExampleDefault ul.navbar-nav {
    gap: 15px;
  }

  /* end of navigation */

  /* Header */
  .header {
    padding-top: 12rem;
  }

  .header .deco-white-circle-1 {
    position: absolute;
    top: 26rem;
    left: -12rem;
    display: block;
    width: 22rem;
    height: 22rem;
  }

  .header .deco-white-circle-2 {
    position: absolute;
    top: 19rem;
    right: -12rem;
    display: block;
    width: 20rem;
    height: 20rem;
  }

  .header .deco-blue-circle {
    position: absolute;
    top: 28rem;
    left: 5rem;
    display: block;
    width: 5rem;
    height: 5rem;
  }

  .header .deco-yellow-circle {
    position: absolute;
    top: 9rem;
    right: 7rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }

  .header .deco-green-diamond {
    position: absolute;
    top: 9rem;
    left: 4rem;
    display: block;
    width: 1rem;
    height: 1rem;
  }
  /* end of header */

  /* Description 1 */
  .basic-1 .image-container {
    margin-bottom: 0;
  }
  /* end of description 1 */

  /* Description 1 Details Lightbox */
  .lightbox-basic {
    padding: 3rem 3rem;
  }

  .lightbox-basic .image-container {
    margin-bottom: 0;
    text-align: left;
  }
  /* end of description 1 details lightbox */

  /* Description 2 */
  .tabs .tabs-container {
    margin-bottom: 0;
  }
  /* end of description 2 */

  /* Features */
  .basic-2 {
    padding-bottom: 8rem;
  }

  .basic-2 .list-unstyled {
    margin-top: 2.5rem;
  }

  .basic-2 .li-space-lg li {
    margin-bottom: 1.875rem;
  }

  .basic-2 .li-space-lg.first li:last-of-type {
    margin-bottom: 1.875rem;
  }

  .basic-2 .fa-stack {
    margin-right: 1rem;
    font-size: 2.25rem;
  }

  .basic-2 .fa-stack .fa-stack-1x {
    font-size: 1.75rem;
  }

  .basic-2 .first .fa-stack {
    position: absolute;
    right: 0;
  }

  .basic-2 .first .media-body {
    max-width: 12.625rem;
    text-align: right;
  }

  .basic-2 img {
    max-width: 100%;
    margin-bottom: 0;
  }
  /* end of features */

  /* Screenshots */
  .slider .swiper-button-prev {
    left: 0;
  }

  .slider .swiper-button-next {
    right: 0;
  }
  /* end of screenshots */

  /* Statistics */
  .counter #counter .cell {
    width: 8.25rem;
  }

  .counter #counter .fas {
    font-size: 3rem;
  }

  .counter #counter .counter-value {
    font-size: 4rem;
    line-height: 4.875rem;
  }
  /* end of statistics */

  /* Download */
  .basic-3 .p-large {
    width: 51rem;
    margin-right: auto;
    margin-left: auto;
  }

  .basic-3 .deco-white-circle-1 {
    position: absolute;
    top: 23rem;
    left: -12rem;
    display: block;
    width: 20rem;
    height: 20rem;
  }

  .basic-3 .deco-white-circle-2 {
    position: absolute;
    top: 7rem;
    right: -12.5rem;
    display: block;
    width: 20rem;
    height: 20rem;
  }

  .basic-3 .deco-blue-circle {
    position: absolute;
    top: 15rem;
    right: 5rem;
    display: block;
    width: 5rem;
    height: 5rem;
  }

  .basic-3 .deco-yellow-circle {
    position: absolute;
    top: 31rem;
    left: 6.5rem;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
  }

  .basic-3 .deco-green-diamond {
    position: absolute;
    top: 8rem;
    right: 12.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
  }
  /* end of download */

  /* Footer */
  .footer .footer-col.first {
    display: none;
  }

  .footer .footer-col.third,
  .footer .footer-col.fourth {
    width: 11.5rem;
  }
  /* end of footer */

  /* Extra Pages */
  .ex-header h1 {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  .ex-basic-2 {
    padding-bottom: 5rem;
  }
  /* end of extra pages */
}
/* end of min-width 992px */

/* Min-width 1200px */
@media (min-width: 1200px) {
  /* Header */
  .header .image-container {
    width: 58.0625rem;
    height: 36.125rem;
    margin-right: auto;
    margin-left: auto;
  }

  .header .deco-white-circle-1 {
    top: 14rem;
    left: -27rem;
    width: 40rem;
    height: 40rem;
  }

  .header .deco-white-circle-2 {
    right: -15rem;
    width: 25.5rem;
    height: 25.5rem;
  }

  .header .deco-blue-circle {
    top: 29rem;
    left: 10rem;
    width: 6rem;
    height: 6rem;
  }

  .header .deco-yellow-circle {
    top: 13.5rem;
    right: 8%;
    width: 2.5rem;
    height: 2.5rem;
  }

  .header .deco-green-diamond {
    top: 7rem;
    left: 300px;
    width: 1.25rem;
    height: 1.25rem;
  }
  /* end of header */

  /* Small Features */
  .cards-1 .card:first-of-type {
    margin-left: 0;
  }

  .cards-1 .card:last-of-type {
    margin-right: 0;
  }
  /* end of small features */

  /* Description 1 */
  .basic-1 .image-container {
    margin-right: 2.5rem;
    width: 31.0625rem;
    height: 36.625rem;
  }

  .basic-1 .text-container {
    margin-top: 5rem;
    margin-left: 2.5rem;
  }
  /* end of description 1 */

  /* Dscription 1 Details Lightbox */
  .lightbox-basic .image-container {
    margin-right: 1.5rem;
  }
  /* end of description 1 details lightbox */

  /* Description 2 */
  .tabs .image-container {
    margin-left: 2.5rem;
    width: 31.0625rem;
    height: 36.625rem;
  }

  .tabs .tabs-container {
    margin-top: 6rem;
    margin-right: 2.5rem;
  }
  /* end of description 2 */

  /* Features */
  .basic-2 .list-unstyled {
    margin-top: 4.5rem;
  }

  .basic-2 .li-space-lg li {
    margin-bottom: 2.875rem;
  }

  .basic-2 .first .media-body {
    max-width: 16.375rem;
    margin-right: 5.75rem;
    margin-left: auto;
  }

  .basic-2 img {
    width: 18.75rem;
    height: 39.8125rem;
  }
  /* end of features */

  /* Screenshots */
  .slider .swiper-container {
    width: 100%;
  }

  .slider .swiper-button-prev {
    left: -2.25rem;
    width: 1.375rem;
    background-size: 1.375rem 2.125rem;
  }

  .slider .swiper-button-next {
    right: -2.25rem;
    width: 1.375rem;
    background-size: 1.375rem 2.125rem;
  }
  /* end of screenshots */

  /* Statistics */
  .counter #counter .cell {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
  /* end of statistics */

  /* Download */
  .basic-3 .image-container {
    width: 57.8125rem;
    height: 36.0625rem;
    margin-right: auto;
    margin-left: auto;
  }

  .basic-3 .deco-white-circle-1 {
    top: 20rem;
    left: -18rem;
    width: 30rem;
    height: 30rem;
  }

  .basic-3 .deco-white-circle-2 {
    top: 3rem;
    right: -20rem;
    width: 30rem;
    height: 30rem;
  }

  .basic-3 .deco-blue-circle {
    top: 17rem;
    right: 7rem;
    width: 6rem;
    height: 6rem;
  }

  .basic-3 .deco-yellow-circle {
    top: 33rem;
    left: 10rem;
    width: 3.5rem;
    height: 3.5rem;
  }

  .basic-3 .deco-green-diamond {
    top: 5rem;
    right: 25%;
    width: 1.5rem;
    height: 1.5rem;
  }
  /* end of download */

  /* Footer */
  .footer .footer-col.first {
    display: inline-block;
    width: 14rem;
    margin-right: 2.5rem;
  }

  .footer .footer-col.second {
    width: 14rem;
    margin-right: 2.25rem;
  }
  .footer .footer-col.third,
  .footer .footer-col.fourth {
    width: 8.5rem;
    margin-right: 3.25rem;
  }

  .footer .fa-stack:last-of-type {
    margin-right: 0;
  }

  .footer .footer-col.fifth {
    width: 12rem;
    margin-right: 0;
  }
  .scroll {
    border: 1px solid ##111218;
    background: black;
    border-radius: 14px;
    text-align: center;
    z-index: 999;
    position: fixed;
    bottom: 10px;
    right: 8px;
    width: 35px;
    padding: 5px;
  }
}

.header-bg {
  background-color: rgba(0,0,0,0.6);
  background-size: cover
}
/* end of min-width 1200px */
