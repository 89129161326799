* {
  font-family: 'Poppins', sans-serif;
}
a {
  text-decoration: none;
}
.Apps {
  background: #1e1e2b;
}
.Apps {
  display: flex;
}
.Apps .sidebar {
  background: #252735;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  width: 280px;
  min-height: 100vh;
  position: fixed;
  z-index: 3;
}
.layout {
  padding: 30px;
  width: 100%;
  min-height: 100vh;
}
.content {
  margin-top: 30px;
  margin-left: 20%;
}
.form-control {
  color: #a7a7a7 !important;
}
.title-heading h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  color: #ffffff;
  margin-bottom: 20px;
}

.clr-white-01 {
  color: #383838 !important;
}
.clr-white-02 {
  color: #4e4e4e !important;
}
.clr-white-03 {
  color: #646464 !important;
}
.clr-white-04 {
  color: #7a7a7a !important;
}
.clr-white-05 {
  color: #909090 !important;
}
.clr-white-06 {
  color: #a7a7a7 !important;
}
.clr-white-07 {
  color: #bcbcbc !important;
}
.clr-white-08 {
  color: #d3d3d3 !important;
}
.clr-white-09 {
  color: #e8e8e8 !important;
}
