.cardio-header-grid {
  display: flex;
  /* grid-template-columns: 25% 1fr; */
  /* column-gap: 35px; */
  align-items: center;
}
.icon-left-with-txt-info {
  display: flex;
  column-gap: 15px;
  align-items: center;
}
.back-to-calendar-h3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  color: #ffffff;
  margin-bottom: 0px;
}
.calendar-y-m-d-top-txt {
  padding-left: 30px;
}
.calendar-y-m-d-top-txt h3 {
  margin-bottom: 0px;
}
span.clr-green.y-m-d-txt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #32b5ff;
  opacity: 0.8;
  padding: 0px 10px;
}
.cardio-top-right-div {
  column-gap: 20px;
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
}
.cardio-body {
  margin-top: 40px;
}
.cardio-content-body-info {
  display: grid;
  grid-template-columns: minmax(310px, 30%) auto;
  column-gap: 20px;
}
h3.comp-to-pre-h3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 0px;
}
.cardio-calendar-content-info {
  max-width: 380px;
  margin-bottom: 20px;
}
.hr-btm-sidebar {
  border: none;
  border-bottom: 1px solid #696969;
  background: #696969;
  margin-bottom: 20px;
  margin-top: 0px;
  width: 94%;
}

.session-overview-info {
  margin-top: 22px;
}
.session-overview-info h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  opacity: 0.8;
  margin-bottom: 10px;
}
.session-overview-info h3.lower-bdy-h3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
  opacity: 0.8;
  margin-bottom: 10px;
}

.lower-body-row .mid-txt h3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #ffffff;
  opacity: 0.8;
  margin-bottom: 0px;
}
.a1-txt h4.clr-green {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #32b5ff;
  opacity: 0.8;
  margin-bottom: 0px;
}
.lower-body-row {
  display: grid;
  grid-template-columns: 10% auto 20%;
  max-width: 180px;
  column-gap: 10px;
  margin-bottom: 15px;
}
.cardio-right-row-info {
  position: relative;
}
.height-100vh {
  height: 100vh;
}
.pl-15px {
  padding-left: 15px;
}
.cardio-heading-h2 h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 20px;
}
.instructions-field-info textarea.form-control {
  background: #1f212d;
  border-radius: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5) !important;
  height: 77px;
  border: none;
  padding: 9px 15px;
}
.instructions-section-info {
  display: grid;
  grid-template-columns: auto 40%;
  column-gap: 15px;
  margin-bottom: 15px;
}
.table-reps-sets-row {
  display: grid;
  grid-template-columns: 85px auto;
  column-gap: 15px;
}
.reps-sets-h4 h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 0px;
}
.reps-sets-no h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #aaaaaa;
  margin-bottom: 0px;
}
.table-reps-sets-field-info {
  display: grid;
  align-items: center;
}
.exercise-row-info {
  background: #252735;
  border-radius: 20px;
  padding: 20px 25px 26px 25px;
}
.exercise-ins-info {
  display: grid;
  grid-template-columns: 100px auto 20px;
  column-gap: 10px;
}
.exercise-ins-txt h3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 4px;
}
.exercise-ins-txt textarea.form-control {
  background: #2e313f;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  border: none;
  padding: 8px 10px;
  /* max-width: 315px; */
  height: 64px;
}
.exercise-ins-info .exercise-ins-img {
  padding: 10px 0px;
}
.drowdown-btn-div select.select-tag-icon {
  background-color: transparent;
  border: none;
  color: #ffffff;
  opacity: 0.7;
  padding: 8px 25px 8px 14px;
  background-position: 90% center;
  background-size: 10px;
}
table.exercise-ins-table-css {
  display: table;
  color: #aaaaaa;
}
table.exercise-ins-table-css tr {
  display: table;
  width: 100%;
  margin-bottom: 10px;
}
.exercise-ins-table {
  margin-top: 15px;
}
table.exercise-ins-table-css thead tr th {
  text-align: center;
}
.drowdown-btn-div select.select-tag-icon option {
  background: #252735;
}
table.exercise-ins-table-css thead tr th span.exer-span-txt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  text-align: center;
  width: 100%;
  opacity: 0.7;
  display: block;
  padding: 9px 0px;
}

table.exercise-ins-table-info {
  display: table;
  color: #aaaaaa;
}
table.exercise-ins-table-info thead tr {
  background: #32b5ff;
}
table.exercise-ins-table-info thead tr {
  border-radius: 10px;
  margin-bottom: 10px;
}
table.exercise-ins-table-info tr {
  display: table;
  width: 100%;
}

table.exercise-ins-table-info thead tr th {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

table.exercise-ins-table-info tbody tr td {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #aaaaaa;
  margin-bottom: 5px;
}

table.exercise-ins-table-info tr th {
  padding: 10px 5px;
  text-align: center;
}

table.exercise-ins-table-info tbody tr td .bg-clr-black-box {
  background: #2e313f;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  display: block;
  padding: 10px 5px;
}
table.exercise-ins-table-info tbody tr {
  background: transparent;
  text-align: center;
}
.btn-superset-table {
  text-align: center;
  display: block;
  clear: both;
}
.btn-superset-table button {
  background: #2e313f;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  color: #fff;
  padding: 10px 35px;
  margin: -15px 0px 0px -80px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.margin-top-mns14 {
  margin-top: -14px;
}
.drowdown-btn-div {
  margin: 0px 5px 0px 0px;
  display: block;
  background: #2e313f;
  box-shadow: 5px 4px 15px rgb(0 0 0 / 50%);
  border-radius: 10px;
  border: none;
}
.margin-top-40px {
  margin-top: 40px;
}
.btn-exercise-table button {
  margin: 0px auto;
  margin-top: 45px;
}
.cardio-top-right-div .btn-top-cstm button {
  margin-right: 0px;
}
.select-date {
  display: flex;
  margin-top: 10px;
}
.select-date h5 {
  padding-left: 10px;
}
.icon-left-heading-cardio {
  margin-left: -14px;
}

.exer-ins-flex {
  display: grid;
  grid-template-columns: auto 43%;
  column-gap: 15px;
}
.exer-ins-sets-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}

.btn-superset-table-cardios button {
  background: #2e313f;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  color: #fff;
  padding: 10px 35px;
  margin-right: -85px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  position: absolute;
  z-index: 99;
}

.exercise-row-info-cardios {
  position: relative;
  margin-top: 15px;
  padding: 20px 25px 40px 25px;
}

.exer-down-arrow {
  position: absolute;
  left: -22px;
  bottom: 35px;
  cursor: pointer;
  transform: rotate(90deg);
}
.exer-up-arrow {
  position: absolute;
  left: -22px;
  top: 35px;
  cursor: pointer;
  transform: rotate(-90deg);
}

table.exercise-ins-table-info tbody tr td input {
  width: 100%;
  padding: 10px 5px;
  color: #aaaaaa;
  text-align: center;
}
